import React, { useEffect, useState } from "react";
import { TextInputProps } from "./definitions";
import { TextInput as TextInputMantine } from "@mantine/core";
import classNames from "classnames";

export const TextInput = ({
  placeholder,
  label,
  error,
  size,
  disabled,
  required,
  type = "text",
  onChange,
  value,
}: TextInputProps) => {
  const [disabledStyle, setDisabledStyle] = useState("");

  useEffect(() => {
    setDisabledStyle(classNames({ "hover:cursor-not-allowed": disabled }));
  }, []);

  return (
    <TextInputMantine
      classNames={{
        input: `rounded-lg p-1 px-2 shadow border-1 border-[#666666] focus:border-2 focus:border-primary ${disabledStyle}`,
      }}
      type={type}
      placeholder={placeholder}
      label={label}
      error={error}
      size={size}
      disabled={disabled}
      required={required}
      onChange={onChange}
      value={value}
    />
  );
};
