import * as React from "react";
import { SVGProps } from "react";

const SvgCamera = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    data-darkreader-inline-fill="currentColor"
    {...props}
  >
    <path d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z" />
    <circle cx={12} cy={13} r={3} />
  </svg>
);

export default SvgCamera;
