import React, { useEffect, useState } from "react";
import { Modal as ModalMantine } from "@mantine/core";
import { ModalProps } from "./definitions";

export const Modal = ({
  title,
  type = "info",
  isOpen = false,
  onClose = () => null,
  size,
  children,
}: ModalProps) => {
  const [typeStyle, setTypeStyle] = useState("text-primary");

  useEffect(() => {
    if (type === "info") setTypeStyle("text-primary");
    if (type === "warning") setTypeStyle("text-warning");
    if (type === "error") setTypeStyle("text-error");
  }, []);

  return (
    <ModalMantine
      //   className="self"
      classNames={{
        modal: "rounded-xl px-20 py-5",
        title: `text-primary text-2xl font-semibold ${typeStyle}`,
        header: "justify-center",
      }}
      opened={isOpen}
      onClose={() => onClose(false)}
      title={title}
      withCloseButton={false}
      centered={true}
      overlayOpacity={0.25}
      size={size}
    >
      {children}
    </ModalMantine>
  );
};
