import { useForceUpdate } from "@mantine/hooks";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { FaCamera } from "react-icons/fa";
import { useCameraFetch } from "src/common/api/hooks";
import { HttpCode } from "src/common/enums";
import { useAccessPoints } from "src/common/hooks";
import {
  useCapturedImagesStore,
  useExpandStore,
  useSetTriggerRefetchStore,
  useTriggerRefetchStore,
} from "src/common/store";
import {
  Button,
  Card,
  CardsContainer,
  ImageContainer,
  SelectInput,
  Tab as TabContainer,
  Typography,
} from "src/components";
import { AccessTab, HouseTab } from "./components";

export const GuardPage = () => {
  const [expanded] = useExpandStore();
  const forceUpdate = useForceUpdate();

  const [, setCamera] = useCapturedImagesStore();
  const trigger = useTriggerRefetchStore();
  const setTrigger = useSetTriggerRefetchStore();

  const accessPoints = useAccessPoints();

  const [selectedLocation, setSelectedLocation] = useState<string>(
    // Selects first access point by default
    accessPoints[0].id,
  );

  const [
    { data: dataFace, loading: loadingFace, error: errorFace },
    refetchFace,
  ] = useCameraFetch({ camera: "face", lid: selectedLocation });

  const [
    { data: dataPlate, loading: loadingPlate, error: errorPlate },
    refetchPlate,
  ] = useCameraFetch({ camera: "plate", lid: selectedLocation });

  const handleSelectLocation = (value: string) => {
    setSelectedLocation(value);
    refetchFace();
    refetchPlate();
  };

  useEffect(() => {
    if (trigger) {
      refetchFace();
      refetchPlate();
      setTrigger(false);
    }
  }, [trigger]);

  // #region Clear Values on Error

  // Both `useEffect`s handle if any errors to remove previous image value.
  // NOTE the `forceUpdate()` is used to force a component re-render. Because
  // the dataFace|dataPlate objects are directly edited it wouldn't normally
  // trigger a re-render.
  useEffect(() => {
    if (Number.isInteger(errorFace?.response?.status)) {
      if (dataFace?.image) {
        dataFace.image = "";
        forceUpdate();
      }
    }
  }, [errorFace?.response?.status]);

  useEffect(() => {
    if (Number.isInteger(errorPlate?.response?.status)) {
      if (dataPlate?.image) {
        dataPlate.image = "";
        forceUpdate();
      }
    }
  }, [errorPlate?.response?.status]);
  //#endregion

  // #region Set Camera Values for Store
  useEffect(() => {
    if (Number.isInteger(errorFace?.response?.status)) {
      setCamera("", "face");
    } else {
      setCamera(dataFace?.image, "face");
      forceUpdate();
    }
  }, [dataFace?.image, errorFace?.response?.status]);

  useEffect(() => {
    if (Number.isInteger(errorPlate?.response?.status)) {
      setCamera("", "plate");
    } else {
      setCamera(dataPlate?.image, "plate");
      forceUpdate();
    }
  }, [dataPlate?.image, errorPlate?.response?.status]);
  //#endregion

  return (
    <CardsContainer className="pl-20">
      <Card className="h-full w-full overflow-y-scroll">
        <Typography type="header" state="info" fontState="bold">
          Cameras
        </Typography>
        <div className="flex flex-col justify-start w-1/2">
          <Typography
            type="subheader"
            state="info"
            label="Re-select Access Point to retake both cameras at once"
            labelPosition="bottom"
          >
            Switch Access Point Cameras
          </Typography>
          <div className="p-1" />
          <SelectInput
            value={selectedLocation}
            onChange={handleSelectLocation}
            options={accessPoints.map((ap: any) => ({
              value: ap.id,
              label: ap.name,
            }))}
          />
        </div>

        <div className="my-5 flex flex-col">
          {/* <div className="w-[10%] self-end">
            <ImageScanButton />
          </div> */}
          <div className="flex justify-between gap-5 flex-wrap">
            <div
              className={`${classNames(
                { "flex-1": !expanded },
                { "w-full": expanded },
              )}`}
            >
              <Typography type="subheader" state="info" fontState="semibold">
                Driver
              </Typography>
              <ImageContainer
                error={errorFace?.response?.status === HttpCode.NOT_FOUND}
                loading={loadingFace}
                image={loadingFace ? "" : dataFace?.image}
              />
              <span className="flex justify-end">
                <Button
                  size="xs"
                  text={loadingFace ? "Capturing..." : "Retake"}
                  disabled={Number.isInteger(errorFace?.response?.status)}
                  loading={loadingFace}
                  onClick={refetchFace}
                  className="mt-2"
                  icon={<FaCamera />}
                />
              </span>
            </div>

            <div
              className={`${classNames(
                { "flex-1": !expanded },
                { "w-full": expanded },
              )}`}
            >
              <Typography type="subheader" state="info" fontState="semibold">
                Plate
              </Typography>
              <ImageContainer
                error={errorPlate?.response?.status === HttpCode.NOT_FOUND}
                loading={loadingPlate}
                image={loadingPlate ? "" : dataPlate?.image}
              />
              <span className="flex justify-end">
                <Button
                  size="xs"
                  disabled={Number.isInteger(errorPlate?.response?.status)}
                  text={loadingPlate ? "Capturing..." : "Retake"}
                  loading={loadingPlate}
                  onClick={refetchPlate}
                  className="mt-2"
                  icon={<FaCamera />}
                />
              </span>
            </div>
          </div>
        </div>
      </Card>

      {/* // TODO fix overflow issues, on either style overflow-y-auto */}
      <TabContainer
        rootStyle="h-full w-full rounded-lg flex flex-col"
        bodyStyle="p-0 h-full "
        tabs={[
          {
            title: "Access",
            content: <AccessTab />,
          },
          // {
          //   title: "Resident",
          //   content: <ResidentsTab />,
          // },
          {
            title: "House",
            content: <HouseTab />,
          },
          // {
          //   title: "Authorized",
          //   content: <AuthorizedTab />,
          // }
        ]}
      />
    </CardsContainer>
  );
};
