import React from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { HeaderProps } from "./definitions";
import { Tooltip } from "./Tooltip";

export const Header = ({
  logo,
  timer,
  emergency,
  showLogout,
  logoutFunc,
}: HeaderProps) => {
  // NOTE padding may be subject to its parent container, in order to have perfect padding on both header content and app content
  return (
    <header className="bg-gradient-to-r from-secondary via-headerGradient to-primary  p-3 md:px-10 md:py-3 flex justify-between items-center h-[10%] w-full">
      {/* // TODO adjust logo sizing */}

      <div className="flex items-center gap-3">
        {logo}
        {showLogout && (
          <Tooltip label="End Shift">
            <FaSignOutAlt
              className="cursor-pointer"
              color="white"
              onClick={logoutFunc}
            />
          </Tooltip>
        )}
      </div>

      {/* desktop  */}
      {timer && <div className="hidden md:block">{timer}</div>}
      {emergency && <div className="hidden md:block">{emergency}</div>}

      {/* mobile */}
      {timer && emergency && (
        <div className="md:hidden flex items-center gap-2">
          <span>{timer}</span>
          {emergency}
        </div>
      )}
    </header>
  );
};
