import { UseFormReturnType } from "@mantine/form/lib/use-form";
import { FaCheck, FaTimes } from "react-icons/fa";
import colors from "src/common/colors";
import { VisitorsPost } from "src/common/schemas";
import { Card, Checkbox, Table, Typography } from "src/components";

export const AccessAuthorizedTable = ({
  authorizedResidentData,
  debounce,
  form,
}: {
  authorizedResidentData: any;
  debounce: string;
  form: any | UseFormReturnType<VisitorsPost>;
}) => {
  // REVIEW don't create "nested" components
  // on each re-render its effectively creating a new component
  const DataTable = () => {
    if (authorizedResidentData.length !== 0) {
      //If there's a house
      if (authorizedResidentData[0]?.length !== 0) {
        //And the house has visitors
        return (
          <Table
            type="card"
            fontSize="sm"
            columns={[
              "Name",
              "Type",
              //"Phone",
              //"House",
              //"Car",
              //"Alert",
              "Status",
              "Access",
            ]}
            rowsData={authorizedResidentData[0].map((data: any) => {
              const {
                fname,
                lname,
                lname2,
                //phone,
                //houseId,
                visitorType,
                //car, alert,
                active,
                //access
              } = data;

              return {
                name: `${fname ? fname : ""} ${lname ? lname : ""} ${
                  lname2 ? lname2 : ""
                }`,
                //house: `${houseId}`,
                type: `${visitorType ? visitorType : "N/A"}`,
                //phone: `${phone ? formatPhoneNumber(phone) : 'N/A'}`,
                status: active ? (
                  <FaCheck color={colors.primary} />
                ) : (
                  <FaTimes color={colors.error} />
                ),
                // car:
                //   <div className="flex items-center">
                //     <div className=""> {car.brand + " " + car.model} </div>
                //     <div className={`ml-2 w-4 h-4 bg-${car.color}-500`}></div>
                //   </div>,
                // alert: alert && <FiAlertCircle color="red" />,
                access: (
                  <Checkbox
                    value={data.id}
                    checked={form.values.grantedBy.includes(data.id)}
                    onChange={(e) =>
                      form.setFieldValue("grantedBy", e.target.value)
                    }
                  />
                ),
              };
            })}
          />
        );
      }
    }
    //Otherwise, render the table message
    return (
      <div className="flex items-center justify-center h-2/3">
        <Typography state="info" fontState="semibold">
          {debounce
            ? "No authorized users"
            : "Type house number to view authorized users"}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <Card className="mt-5 flex-1 overflow-y-auto">
        <div className="w-full sticky -top-6 bg-white">
          <Typography type="subheader" state="info" fontState="medium">
            Authorized
          </Typography>
        </div>
        <DataTable />
      </Card>
    </>
  );
};
