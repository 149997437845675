import { UseFormReturnType } from "@mantine/form/lib/use-form";
import { FaCheck, FaTimes } from "react-icons/fa";
import colors from "src/common/colors";
import { VisitorsPost } from "src/common/schemas";
import { formatPhoneNumber } from "src/common/utils";
import { Card, Checkbox, Table, Typography } from "src/components";

export const AccessDataTable = ({
  houseData,
  debounce,
  form,
}: {
  houseData: any;
  debounce: string;
  form: any | UseFormReturnType<VisitorsPost>;
}) => {
  /** The house data based on house number, singular data */

  return (
    <Card className="mt-5 flex-1 overflow-y-auto">
      <div className="w-full sticky -top-6 bg-white">
        <Typography type="subheader" state="info" fontState="medium">
          House{" "}
          <span className="ml-3 underline">
            {houseData[0]?.number &&
              `${houseData[0]?.number} ${houseData[0]?.address}`}
          </span>
        </Typography>
      </div>

      {/* //TODO fix overflow y issue, its not triggering on this Card*/}
      {houseData.length === 0 ? (
        <div className="flex items-center justify-center h-2/3">
          <Typography state="info" fontState="semibold">
            {debounce
              ? "House not found"
              : "Type house number to start access process"}
          </Typography>
        </div>
      ) : (
        <Table
          type="card"
          fontSize="sm"
          columns={[
            "Resident",
            "Phone",
            "A. Priority",
            "Status",
            "Permit",
            // "Pin",
          ]}
          rowsData={[
            ...houseData[0].mainContact,
            ...houseData[0].residents,
          ].map((data: any) => {
            const { fname, lname, lname2, phone, isMainContact, active } = data;

            return {
              resident: `${fname ? fname : ""} ${lname ? lname : ""} ${
                lname2 ? lname2 : ""
              }`,
              phone: formatPhoneNumber(phone),
              priority: (
                <Typography state={isMainContact ? "info" : undefined}>
                  {isMainContact ? "Primary" : "Secondary"}
                </Typography>
              ),
              status: active ? (
                <FaCheck color={colors.primary} />
              ) : (
                <FaTimes color={colors.error} />
              ),
              permit: (
                <Checkbox
                  value={data.id}
                  // form="visitorForm"
                  // checked={permit}
                  checked={form.values.grantedBy.includes(data.id)}
                  onChange={(e) =>
                    form.setFieldValue("grantedBy", e.target.value)
                  }
                />
              ),
            };
          })}
        />
      )}
    </Card>
  );
};
