import {useQuery, useQueryClient} from "react-query";
import {getAccessLogs, getHouses, getUsers, getVisitorLogs} from "src/common/api";
import {LogRes, UserRes, VisitorLogRes} from "src/common/api/types";
import {AxiosError} from "axios";


export function useHouseQuery() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  return useQuery(["housesData", clusterId], () =>
      getHouses({clusterId}),
  );
}

export function useAccessPoints() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  return user.clusters[0].locations;
}

export function useClusterInfo() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  return user.clusters[0];
}

export function useUsersQuery() {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  return useQuery<UserRes, AxiosError>(
      ["usersData", clusterId],
      () => getUsers({clusterId}),
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
  );
}

export function useAccessLogs({page, perPage, userId, knowns, unknowns}: {
  page?: number;
  perPage?: number;
  userId?: string;
  knowns?: boolean;
  unknowns?: boolean;
}) {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  return useQuery<LogRes, AxiosError>(
      ["accessLogs", clusterId, page, perPage, userId, knowns, unknowns],
      () => getAccessLogs({clusterId, page, perPage, userId, knowns, unknowns}),
      {keepPreviousData: true},
  );
}

export function useVisitorLogs({page, perPage}: {
  page?: number;
  perPage?: number;
}) {
  const queryClient = useQueryClient();
  const user: any = queryClient.getQueryData("userAuth");
  const clusterId = user.clusters[0].id;
  return useQuery<VisitorLogRes, AxiosError>(
      ["visitorLogs", clusterId, page, perPage],
      () => getVisitorLogs({clusterId, page, perPage}),
      {keepPreviousData: true},
  );
}

