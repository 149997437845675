import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Loader } from "src/components/Loader";
import { Typography } from "src/components/Typography";
import { Camera, Expand } from "./assets/icons";
import { ImageContainerProps } from "./definitions";
import { Modal } from "./Modal";

export const ImageContainer = ({
  icon = (
    <Camera
      className="stroke-primary fill-white"
      height={"50%"}
      width={"50%"}
    />
  ),
  loading,
  error,
  fullscreenControl,
  image,
  className,
}: ImageContainerProps) => {
  // NOTE the size will be determined by container and image provided
  // NOTE so adjust the image according to its container, this is to make it responsive. This is all possible because of w-fit className
  const [withImageStyle, setWithImageStyle] = useState("");
  const [openFullScrImg, setOpenFullScrImg] = useState(false);

  useEffect(() => {
    setWithImageStyle(
      classNames({ "opacity-25 hover:bg-white hover:opacity-100": image }),
    );

    return setOpenFullScrImg(false);
  }, []);

  /** To "rally up" the props for the `<ImageContent />` */
  const props = { image, icon, loading, error };

  return (
    <div
      className={`bg-white p-3 rounded-xl shadow-xl flex justify-center items-center aspect-camera relative ${className}`}
    >
      <Modal isOpen={openFullScrImg} onClose={setOpenFullScrImg} size="75%">
        <div className="aspect-video flex flex-col justify-center text-center">
          <Typography type="header" state="info" pulsing>
            Full image size will be saved on entry submit
          </Typography>
          <div className="p-1" />
          <img
            className="object-cover h-full rounded-xl"
            src={image}
            alt="image"
          />
        </div>
      </Modal>

      {(fullscreenControl || image) && (
        <button
          className={`bg-white w-fit h-[12%] absolute top-5 right-5 p-3 rounded-lg grid place-items-center hover:bg-gray-200 ${withImageStyle} transition`}
          onClick={() => setOpenFullScrImg(true)}
        >
          <Expand height={"100%"} width={"100%"} />
        </button>
      )}

      <ImageContent {...props} />
    </div>
  );
};

const ImageContent = ({
  image,
  icon,
  loading,
  error,
}: {
  image: string;
  icon: JSX.Element;
  loading: boolean | undefined;
  error: boolean | undefined;
}) => {
  if (!image) return icon;
  if (image === null) return icon;

  // TODO perhaps use another icon or different case for errors
  if (error) return icon;
  if (loading) return <Loader />;

  return (
    <img
      className="object-cover h-full rounded-xl"
      src={image}
      alt="gate camera image"
    />
  );
};
