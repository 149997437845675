import useAxios from "axios-hooks";
import { baseURL } from "src/common/api";
import { headersAuth } from "src/common/auth";

interface ICameraFetch {
  /** Determines which camera placement to choose if available. */
  camera: "plate" | "face";
  /** Location id to fetch images from station. */
  lid: string;
}
export function useCameraFetch({ camera, lid }: ICameraFetch) {
  return useAxios(
    {
      baseURL,
      url: `/cameras/${lid}`,
      headers: headersAuth(),
      //`type` query-param expects either "p" or "f", hence getting first char
      params: { type: camera.charAt(0) },
    },
    {
      manual: !lid,
      useCache: false,
      autoCancel: false,
    },
  );
  // return stuff;
}
