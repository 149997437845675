import { LoadingOverlay as MLoadingOverlay } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import { useDebouncedValue } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import axios from "axios";
import dayjs from "dayjs";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { baseURL, getCarOptions, postVisitor } from "src/common/api";
import { House } from "src/common/api/types";
import { headersAuth } from "src/common/auth";
import { useClusterInfo, useHouseQuery } from "src/common/hooks";
import { visitorSchema } from "src/common/schemas";
import {
  useCapturedImagesStore,
  useSetTriggerRefetchStore,
} from "src/common/store";
import { dataUrlToFile } from "src/common/utils";
import {
  Button,
  Card,
  ChipsInputGroup,
  ColorInput,
  Loader,
  SelectInput,
  TextInput,
  Typography,
} from "src/components";
import {
  AllowAccessModal,
  DenyAccessModal,
} from "src/pages/guard/features/modals";
import {
  AccessAuthorizedTable,
  AccessDataTable,
} from "src/pages/guard/features/tables";

export const AccessTab = () => {
  // const capturedImages = useCapturedImagesStore();
  const [capturedImages, setCapturedImages] = useCapturedImagesStore();
  const [imageLoading, setImageLoading] = useState(false);
  const [openDenyModal, setOpenDenyModal] = useState(false);
  const [openAllowModal, setOpenAllowModal] = useState(false);
  const [houseNumber, setHouseNumber] = useState("");
  const [debouncedHouseNumber] = useDebouncedValue(houseNumber, 500);
  const setCamFetchTrigger = useSetTriggerRefetchStore();
  const { data: housesData, refetch: housesRefetch } = useHouseQuery();
  const { data: carBrandsData } = useQuery(["carOptionsData"], () =>
    getCarOptions(),
  );
  console.log("carBrandsData", capturedImages.face);
  const form = useForm({
    schema: zodResolver(visitorSchema),
    initialValues: {
      // TODO if "global form progress retention" is needed, initial values should come from a store
      fname: "",
      lname: "",
      carModel: "",
      carBrand: "",
      carColor: "",
      carPlate: "",
      visitorType: "",
      houseId: "",
      grantedBy: "",
      wasGranted: false,
      locationId: "",
      timeStart: dayjs().toISOString(),
      timeEnd: dayjs().toISOString(),
      timeElapsed: 0,
      details: "",
      addedAt: dayjs().toISOString(),
    },
  });

  const cluster = useClusterInfo();

  const {
    mutate: submitPostVisitor,
    status: rqStatus,
    isLoading,
  } = useMutation(postVisitor, {
    onSuccess: (res) => {
      form.reset();
      setHouseNumber("");
      setOpenAllowModal(false);
      setOpenDenyModal(false);
      setCamFetchTrigger(true);
      showNotification({
        title: "Success",
        message:
          res.statusCode === 202
            ? "Information submitted, but gate could not open."
            : "Information submitted.",
        color: res.statusCode === 202 ? "yellow" : "green",
      });
    },
    onError: (res: any) => {
      form.reset();
      setHouseNumber("");
      setOpenAllowModal(false);
      setOpenDenyModal(false);
      showNotification({
        title: "Error",
        message: "An error ocurred",
        color: "red",
      });
    },
  });

  const handleHouseChange = (e: any) => {
    setCamFetchTrigger(true);
    setHouseNumber(e);
    form.setFieldValue("houseId", e);
  };

  return (
    <Card expandable className="h-full w-full flex flex-col">
      {false && <MLoadingOverlay visible loader={<Loader size={"lg"} />} />}
      <DenyAccessModal
        isOpen={openDenyModal}
        form={form}
        submitLoading={imageLoading || isLoading}
        onClose={() => setOpenDenyModal(false)}
      />
      <AllowAccessModal
        isOpen={openAllowModal}
        form={form}
        submitLoading={imageLoading || isLoading}
        onClose={() => setOpenAllowModal(false)}
      />

      {Object.keys(form.errors).length > 0 && (
        <Typography state="error">{JSON.stringify(form.errors)}</Typography>
      )}
      <div className="flex justify-end">
        <Button
          size="xs"
          text="Reset Form"
          onClick={() => {
            form.reset();
            setCamFetchTrigger(true);
          }}
        />
      </div>
      <div className="flex flex-wrap gap-5">
        <ChipsInputGroup
          onChange={(value) => {
            form.setFieldValue("visitorType", value);
          }}
          value={form.values.visitorType}
          chips={[
            { value: "VISITOR", children: "Visitor" },
            {
              value: "SERVICE",
              children: cluster ? `Service for ${cluster.name}` : "Service",
            },
            { value: "CONTRACTOR", children: "Contractor" },
          ]}
        />
      </div>
      <form
        id="visitorForm"
        className="flex flex-wrap gap-x-3 gap-y-1"
        onSubmit={form.onSubmit(async (data) => {
          let faceUrl: any;
          let plateUrl: any;

          // #region returned URLs
          // Note: url is return if there's an image, made separate `if` for it
          if (capturedImages.face) {
            setImageLoading(true);

            faceUrl = await uploadCameraCapture(
              capturedImages.face,
              data.locationId, // access-point id
            );
          }

          if (capturedImages.plate) {
            setImageLoading(true);

            plateUrl = await uploadCameraCapture(
              capturedImages.plate,
              data.locationId, // access-point id
            );
          }
          // #endregion

          if (plateUrl || faceUrl) {
            setImageLoading(false);
            showNotification({
              title: "Success",
              message: "Images uploaded successfully",
              color: "green",
            });
          }

          const body = {
            ...data,
            faceImage: faceUrl && faceUrl.data.location,
            plateImage: plateUrl && plateUrl.data.location,
          };
          // FIX figure out why TS complains here
          // @ts-ignore
          return submitPostVisitor(body);
        })}
      >
        <TextInput
          label="Plate #"
          size="xs"
          {...form.getInputProps("carPlate")}
          value={form.values.carPlate}
        />
        <SelectInput
          label="House"
          size="xs"
          options={
            !housesData
              ? []
              : housesData?.houses?.map((house: House) => {
                  return { value: house.id, label: house.number };
                })
          }
          onDropdownOpen={() => housesRefetch()}
          onSearchChange={(e) => setHouseNumber(e)}
          onChange={(e) => handleHouseChange(e)}
        />
        <TextInput
          label="First Name"
          size="xs"
          {...form.getInputProps("fname")}
        />
        <TextInput
          label="Last Name"
          size="xs"
          {...form.getInputProps("lname")}
        />
        <SelectInput
          label="Car Brand"
          size="xs"
          {...form.getInputProps("carBrand")}
          options={
            !carBrandsData
              ? []
              : carBrandsData?.carBrands?.map((carBrand: any) => {
                  return { value: carBrand.name, label: carBrand.name };
                })
          }
          value={form.values.carBrand}
        />
        <SelectInput
          label="Model"
          size="xs"
          // creatable
          {...form.getInputProps("carModel")}
          options={
            !carBrandsData
              ? []
              : [
                  ...carBrandsData?.carBrands
                    .filter(
                      (carBrand: any) => carBrand.name === form.values.carBrand,
                    )
                    .map((carBrand: any) => {
                      return carBrand?.brandModels?.map((model: any) => {
                        return model.name;
                      });
                    })
                    .flat(),
                ]
          }
          value={form.values.carModel}
        />
        <ColorInput
          label="Color"
          size="xs"
          showColorSwatches
          {...form.getInputProps("carColor")}
        />
      </form>

      {/* House access table */}
      <AccessDataTable
        form={form}
        debounce={debouncedHouseNumber}
        houseData={
          housesData
            ? housesData?.houses?.filter(
                (house: House) => house.number === debouncedHouseNumber,
              )
            : []
        }
      />

      {/* TODO: Add in relevant authorized visitors data and form object.*/}
      <AccessAuthorizedTable
        form={form}
        authorizedResidentData={
          housesData
            ? [
                ...housesData?.houses
                  ?.filter(
                    (house: House) =>
                      house.number === debouncedHouseNumber && house.visitors,
                  )
                  .map((house: House) => house.visitors),
              ]
            : [[]]
        }
        debounce={debouncedHouseNumber}
      />

      {/* allow or deny access buttons */}
      <div className="mt-3 w-fit self-end flex gap-3">
        <Button
          text="Deny Access"
          size="sm"
          variant="outlined"
          color="error"
          onClick={() => {
            form.setFieldValue("wasGranted", false);
            setOpenDenyModal(true);
          }}
        />
        {/* //TODO allow button should be enabled when an access checkbox on house table is checked */}
        <Button
          text="Allow Access"
          size="sm"
          variant="outlined"
          onClick={() => {
            form.setFieldValue("wasGranted", true);
            setOpenAllowModal(true);
          }}
        />
      </div>
    </Card>
  );
};

/** Handles uploading provided image to cameras bucket.
 * @returns an object with the url of the image in the bucket. */
async function uploadCameraCapture(image: string, accessPointId: string) {
  const formData = new FormData();
  const uploadFile = await dataUrlToFile(image, "image.jpeg");
  formData.append("file", uploadFile);

  const response = await axios({
    method: "post",
    url: `${baseURL}/cameras/upload/${accessPointId}`,
    data: formData,
    headers: {
      ...headersAuth(),
      Accept: "multipart/form-data",
    },
  });
  return response;
}
