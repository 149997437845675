import React, { useEffect, useState } from "react";
import { CheckboxProps } from "./definitions";
import { Checkbox as CheckboxMantine } from "@mantine/core";
import classNames from "classnames";

export const Checkbox = ({
  checked,
  label,
  disabled,
  size,
  onChange,
  value,
  form,
}: CheckboxProps) => {
  const [disabledStyle, setDisabledStyle] = useState("");

  useEffect(() => {
    setDisabledStyle(
      classNames(
        { "hover:cursor-not-allowed grayscale": disabled },
        { "hover:cursor-pointer ": !disabled },
      ),
    );
  }, []);

  return (
    <CheckboxMantine
      //   className="checked:bg-primary checked:fill-primary"
      classNames={{
        input: `rounded-sm checked:bg-primary checked:border-primary ${disabledStyle}`,
      }}
      form={form}
      value={value}
      checked={checked}
      label={label}
      disabled={disabled}
      size={size}
      onChange={onChange}
    />
  );
};
