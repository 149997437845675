import create from "zustand";

// TODO can be simplified using jotai
type TriggerRefetch = {
  triggerRefetch: boolean;
  setTriggerRefetch: (value: boolean) => void;
};
const useTriggerRefetch = create<TriggerRefetch>((set) => ({
  triggerRefetch: false,
  setTriggerRefetch(value: boolean) {
    set(() => {
      return {
        triggerRefetch: value,
      };
    });
  },
}));

// prettier-ignore
export const useTriggerRefetchStore = () => useTriggerRefetch((state) => state.triggerRefetch);
// prettier-ignore
export const useSetTriggerRefetchStore = () => useTriggerRefetch((state) => state.setTriggerRefetch);
