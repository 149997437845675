import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { NavBarLinkProps } from "./definitions";

// IDEA "manually adjust size with tailwind classes and style prop???"
// NOTE stroke = "lines", fill = "svg inner fill backdrop", bg = "svg icon background"
// NOTE the size of the svg depends on the button container for now

/** The link button for the navbar */
export const NavBarLink = ({
  to,
  replace,
  icon,
  active,
  disabled,
  className,
}: NavBarLinkProps) => {
  // const [disabledStyle, setDisabledStyle] = useState("");

  // useEffect(() => {
  //   setActiveStyle(classNames({ "!bg-primary": active }));
  //   setIconActiveStyle(classNames({ "!stroke-white": active }));
  //   setDisabledStyle(
  //     classNames({
  //       "!cursor-not-allowed grayscale text-white opacity-50": disabled,
  //     })
  //   );
  // }, []);

  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? `bg-primary stroke-white flex justify-center items-center p-2.5 hover:bg-primary rounded-lg shadow-xl group aspect-square cursor-pointer ${className}`
          : `bg-white stroke-primary flex justify-center items-center p-2.5 hover:bg-primary rounded-lg shadow-xl group aspect-square cursor-pointer ${className}`
      }
      to={to}
      replace={replace}
    >
      <span className="text-transparent bg-transparent stroke-inherit group-hover:stroke-white">
        {icon}
      </span>
    </NavLink>
  );
};
