import { useForm, zodResolver } from "@mantine/form";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getUser, postLogin } from "src/common/api";
import { loginSchema } from "src/common/schemas";
import { Button, Card, Header, TextInput, Typography } from "src/components";
import { SvgLogo as Logo } from "src/components/assets";

export const Login = () => {
  const location: any = useLocation();
  let from = location.state?.from?.pathname || "/post";
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const form = useForm({
    schema: zodResolver(loginSchema),
    initialValues: {
      email: "",
      password: "",
    },
  });
  // const [output, setOutput] = useState<object>();
  const [loginError, setLoginError] = useState<string | number>();
  const { mutate: submitPostLogin, status: rqStatus } = useMutation(postLogin, {
    onSuccess: async (data) => {
      // TODO do proper error ux
      if (data.rn !== 8) {
        return setLoginError(401);
      }

      sessionStorage.setItem("token", data.access_token);
      const user = await getUser({ userId: data.id, token: data.access_token });
      await queryClient.setQueryData("userAuth", user);

      navigate(from, { replace: true });
    },
    onError: (error: any) => {
      setLoginError(error.request.status);
    },
  });

  const renderError = () =>
    // TODO handle other errors, base on status code (ej. 500, etc), or default case
    loginError === 401 && (
      <Typography className="text-center" state="error">
        Invalid email or password
      </Typography>
    );

  return (
    <>
      <Header logo={<Logo height={"40%"} width={"40%"} />} />

      <form
        className="grid h-[90%] place-items-center"
        // TODO make onSubmit loader, perhaps use the useMutation hook
        onSubmit={form.onSubmit((credentials) => {
          submitPostLogin(credentials);
        })}
      >
        <Card className="w-1/2 flex flex-col gap-4">
          <Typography type="header" state="info" fontState="bold">
            Welcome back guard!
          </Typography>

          <TextInput
            placeholder="Email"
            type="email"
            {...form.getInputProps("email")}
          ></TextInput>
          <TextInput
            placeholder="Password"
            type="password"
            {...form.getInputProps("password")}
          ></TextInput>

          {renderError()}

          <Button loading={rqStatus === "loading"} text="Login" type="submit" />
          {process.env.NODE_ENV === "development" &&
            JSON.stringify({
              email: "Daren.Mueller12@example.org",
              password: "123456!",
            })}
        </Card>
      </form>
    </>
  );
};
