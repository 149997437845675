import * as React from "react";
import { SVGProps } from "react";

const SvgExpand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 29 29"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M8.69.008H1.101C.497.008.008.497.008 1.102v7.656c0 .3.246.547.547.547h1.64c.301 0 .547-.246.547-.547V2.742H8.69c.301 0 .547-.246.547-.547V.555c0-.301-.246-.547-.547-.547Zm19.755 19.687h-1.64c-.301 0-.547.246-.547.547v6.016H20.31c-.301 0-.547.246-.547.547v1.64c0 .301.246.547.547.547h7.587c.605 0 1.094-.489 1.094-1.094v-7.656c0-.3-.246-.547-.547-.547ZM8.69 26.258H2.742v-6.016c0-.3-.246-.547-.547-.547H.555c-.301 0-.547.246-.547.547v7.656c0 .605.489 1.094 1.094 1.094h7.587c.301 0 .547-.246.547-.547v-1.64c0-.301-.246-.547-.547-.547ZM27.9.008H20.31c-.301 0-.547.246-.547.547v1.64c0 .301.246.547.547.547h5.947v6.016c0 .3.246.547.547.547h1.64c.301 0 .547-.246.547-.547V1.102c0-.605-.489-1.094-1.094-1.094Z" />
  </svg>
);

export default SvgExpand;
