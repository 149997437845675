import React from "react";
import { TooltipProps } from "./definitions";
import { Tooltip as TooltipMantine } from "@mantine/core";

export const Tooltip = ({
  children,
  label,
  position = "top",
  placement = "center",
  withArrow = true,
  disabled,
}: TooltipProps) => {
  return (
    <TooltipMantine
      classNames={{
        // root: children && children[0].className,
        // "p-3 bg-yellow-500 text-white rounded-md"
        body: "bg-primary rounded-md px-5 py-3",
        arrow: "bg-primary rounded-none",
      }}
      label={label}
      position={position}
      placement={placement}
      withArrow={withArrow}
      arrowSize={8}
      disabled={disabled}
      // closeDelay={800}
    >
      {children}
    </TooltipMantine>
  );
};
