import React from "react";
import { Loader as LoaderMantine } from "@mantine/core";
import { LoaderProps } from "./definitions";

export const Loader = ({
  color = "#0D9E90",
  size = "md",
  variant = "dots",
}: LoaderProps) => {
  return <LoaderMantine color={color} size={size} variant={variant} />;
};
