import React from "react";
import { MainContainerProps } from "./definitions";

export const MainContainer = ({ children, className }: MainContainerProps) => {
  return (
    <main className={`h-[90%] p-3 md:px-10 md:py-3 ${className}`}>
      {children}
    </main>
  );
};
