import * as React from "react";
import { SVGProps } from "react";

const SvgExpandCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16 4 6 14l10 10-2 4L0 14 14 0l2 4Z" fill="currentColor" />
  </svg>
);

export default SvgExpandCard;
