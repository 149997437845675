import { Pagination, Switch } from "@mantine/core";
import classNames from "classnames";
import dayjs from "dayjs";
import { useState } from "react";
import {
  FaCarSide,
  FaCheck,
  FaLaptopHouse,
  FaQrcode,
  FaSearch,
  FaTag,
  FaTimes,
  FaWalking,
} from "react-icons/fa";
import colors from "src/common/colors";
import { useAccessLogs, useUsersQuery, useVisitorLogs } from "src/common/hooks";
import {
  Card,
  CardsContainer,
  Loader,
  SelectInput,
  Tab,
  Table,
  Tooltip,
  Typography,
} from "src/components";
import { RefreshButton } from "../../common/features";

export const Logs = () => {
  const { data: usersData, isSuccess: usersSuccess } = useUsersQuery();
  const [userId, setUserId] = useState<string | undefined>(undefined);
  return (
    <CardsContainer className="pl-20">
      <Tab
        rootStyle="h-full w-full flex flex-col"
        bodyStyle="h-[92%]"
        tabPadding={0}
        tabs={[
          {
            title: "Log",
            content: (
              <>
                <div className="absolute top-3 right-14 flex justify-end gap-3">
                  <div className="flex justify-end ">
                    <SelectInput
                      clearable={true}
                      icon={<FaSearch size={14} />}
                      className="w-72"
                      placeholder="Search user"
                      options={
                        usersSuccess && usersData
                          ? usersData.users.map((user: any) => {
                              return {
                                label: `${user.fname} ${user.lname} ${user.lname2}`,
                                value: user.id,
                              };
                            })
                          : [{ label: "error", value: "error" }]
                      }
                      onChange={(value) => {
                        setUserId(value === null ? undefined : value);
                      }}
                    />
                  </div>
                </div>

                {/* table */}
                <Card className="h-full w-full overflow-y-auto pt-0">
                  <LogsDataTable userId={userId} />
                </Card>
              </>
            ),
          },
          {
            title: "Visitors Log",
            content: (
              <>
                <VisitorLogsDataTable />
              </>
            ),
          },
        ]}
      />
    </CardsContainer>
  );
};

const LogsDataTable = ({ userId }: { userId?: string }) => {
  const [activePage, setPage] = useState(1);
  const [unknown, setUnknown] = useState(false);
  const {
    data: logsData,
    isLoading,
    refetch,
    isRefetching,
  } = useAccessLogs({
    page: activePage,
    perPage: 15,
    knowns: true,
    unknowns: unknown,
    userId,
  });

  if (isLoading || !logsData) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <>
      <div className="flex flex-row justify-end">
        <Switch
          size="sm"
          label="Show Unknown"
          className="px-3"
          classNames={{ input: "cursor-pointer" }}
          color="teal"
          onChange={() => setUnknown((value) => !value)}
        />
        <RefreshButton isRefreshing={isRefetching} refetchMethod={refetch} />
      </div>

      <div className="flex h-[90%] flex-col content-between justify-between">
        <Table
          fontSize="xs"
          tableHeaderStyle="sticky top-0 bg-white z-10"
          columns={[
            "Key",
            "Method",
            "Time",
            "Name",
            "House Number",
            "Approval",
            "Type",
            "Access Point",
          ]}
          rowsData={logsData.logs.map((log) => {
            const {
              key: { type: keyType, value },
              method,
              time,
              status,
              accessPoint: { name: accessPoint },
            } = log;

            // destructure the user object from log, user may be null
            const { name } = log.user || {
              name: "Unknown",
            };

            const checkTooltipIcon = (method: string | null | undefined) => {
              if (method === "QRCODE")
                return <FaQrcode color={colors.primary} size={"1.5rem"} />;
              if (method === "RFID")
                return <FaTag color={colors.primary} size={"1.5rem"} />;
              if (method === "GUARDPOST")
                return <FaLaptopHouse color={colors.primary} size={"1.5rem"} />;

              return null;
            };
            const checkKeyMethod = (method: string | null | undefined) => {
              if (method === "QRCODE") {
                return (
                  <>
                    <FaQrcode />
                    {value}
                  </>
                );
              }

              if (method === "RFID") {
                return (
                  <>
                    <FaTag />
                    {value}
                  </>
                );
              }

              if (method === "GUARDPOST") {
                return (
                  <>
                    <FaLaptopHouse />
                    {value}
                  </>
                );
              }

              return null;
            };
            const checkEntryMethod = (method: string | null | undefined) => {
              if (method === "PEDESTRIAN")
                return <FaWalking color={colors.primary} size={"1.5rem"} />;
              if (method === "VEHICLE")
                return <FaCarSide color={colors.primary} size={"1.5rem"} />;

              return null;
            };

            return {
              key: (
                <Tooltip
                  label={
                    <div className="flex flex-col flex-wrap gap-3">
                      <p className="self-center">Access Methods</p>

                      <div className="flex flex-col gap-3">
                        <div className="flex items-center gap-3">
                          {checkKeyMethod(keyType)}
                        </div>
                      </div>
                    </div>
                  }
                >
                  <span className="flex flex-row gap-1">
                    {checkTooltipIcon(keyType)}
                    {keyType === "RFID" && value}
                  </span>
                </Tooltip>
              ),
              method: checkEntryMethod(method),
              time: dayjs(time).format("MMM/DD - h:mm:ss A"),
              user: `${name}`,
              houses: log.houses?.map((house) => house.number).join(", "),
              status: status ? (
                <FaCheck color={colors.primary} size={"1.5rem"} />
              ) : (
                <FaTimes color={colors.error} size={"1.5rem"} />
              ),
              type: log.type ?? "UNKNOWN",
              accessPoint,
            };
          })}
        />
        <Pagination
          classNames={{
            active: "border-2 border-primary text-primary",
          }}
          position="center"
          size="sm"
          total={logsData.totalPages}
          siblings={1}
          page={activePage}
          onChange={setPage}
        />
      </div>
    </>
  );
};

const VisitorsTypeCountPill = ({
  type,
  count,
}: {
  type: string;
  count: number;
}) => {
  const formatter = new Intl.NumberFormat("en-US", { notation: "compact" });

  return (
    <span
      className={`${classNames(
        { "bg-warning": type === "service" },
        { "bg-error": type === "visitor" },
        { "bg-primary": type === "contractor" },
      )} flex items-center gap-3 rounded-full px-3 py-1 text-white`}
    >
      {`${type.charAt(0).toUpperCase() + type.slice(1)}`}{" "}
      <span className="rounded-full bg-white px-2 text-sm text-black">
        {formatter.format(count)}
      </span>
    </span>
  );
};

const VisitorLogsDataTable = () => {
  const [activePage, setPage] = useState(1);
  const {
    data: visitorLogsData,
    isLoading,
    refetch,
    isRefetching,
  } = useVisitorLogs({
    page: activePage,
    perPage: 15,
  });

  if (isLoading || !visitorLogsData) {
    return (
      <div className="flex h-full items-center justify-center">
        <Loader size="lg" />
      </div>
    );
  }

  const {
    typeTotals: { contractor, service, visitor },
  } = visitorLogsData;

  return (
    <>
      <div className="absolute top-3 right-14 flex justify-end gap-3">
        <VisitorsTypeCountPill type="service" count={service} />
        <VisitorsTypeCountPill type="visitor" count={visitor} />
        <VisitorsTypeCountPill type="contractor" count={contractor} />
        <RefreshButton isRefreshing={isRefetching} refetchMethod={refetch} />
      </div>

      <Card className="h-full w-full overflow-y-auto pt-0">
        <div className="flex h-[90%] flex-col content-between justify-between">
          <Table
            fontSize="xs"
            tableHeaderStyle="sticky top-0 bg-white z-10"
            columns={[
              "Name",
              "House",
              "Approved",
              "Approver",
              "Check-in Method",
              "Check-in Access Point",
              "Check-in Time",
            ]}
            rowsData={visitorLogsData.visitorLogs.map((visitorLog) => {
              const {
                visitor: { fname: userFname, lname: userLname },
                visitorType,
                house: { number },
                approved,
                approver: {
                  fname: approverFname,
                  lname: approverLname,
                  lname2: approverLname2,
                },
                checkInMethod: { type, value },
                checkInAccessPoint: { name: checkInAccessPoint },
                checkInTime,
              } = visitorLog;
              const checkVisitorType = (
                visitorType: string | null | undefined,
              ) => {
                if (visitorType === "VISITOR") return "error";
                if (visitorType === "CONTRACTOR") return "info";
                if (visitorType === "SERVICE") return "warning";

                return undefined;
              };
              const checkTooltipIcon = (method: string | null | undefined) => {
                if (method === "QRCODE")
                  return <FaQrcode color={colors.primary} size={"1.5rem"} />;
                if (method === "RFID")
                  return <FaTag color={colors.primary} size={"1.5rem"} />;
                if (method === "GUARDPOST")
                  return (
                    <FaLaptopHouse color={colors.primary} size={"1.5rem"} />
                  );

                return null;
              };
              const checkInMethod = (method: string | null | undefined) => {
                if (method === "QRCODE") {
                  return (
                    <>
                      <FaQrcode />
                      {value}
                    </>
                  );
                }

                if (method === "RFID") {
                  return (
                    <>
                      <FaTag />
                      {value}
                    </>
                  );
                }

                if (method === "GUARDPOST") {
                  return (
                    <>
                      <FaLaptopHouse />
                      {value}
                    </>
                  );
                }

                return null;
              };

              return {
                name: (
                  <Typography
                    className="text-xs"
                    state={checkVisitorType(visitorType)}
                    fontState="medium"
                  >
                    {`${userFname} ${userLname}`}
                  </Typography>
                ),
                house: number,
                approved: approved ? (
                  <FaCheck color={colors.primary} size={"1.5rem"} />
                ) : (
                  <FaTimes color={colors.error} size={"1.5rem"} />
                ),
                approver: `${approverFname} ${approverLname} ${approverLname2}`,
                checkInMethod: (
                  <Tooltip
                    label={
                      <div className="flex flex-col flex-wrap gap-3">
                        <p className="self-center">Access Methods</p>

                        <div className="flex flex-col gap-3">
                          <div className="flex items-center gap-3">
                            {checkInMethod(type)}
                          </div>
                        </div>
                      </div>
                    }
                  >
                    {checkTooltipIcon(type)}
                  </Tooltip>
                ),
                checkInAccessPoint,
                checkInTime: dayjs(checkInTime).format("MMM/DD - h:mm:ss A"),
              };
            })}
          />
          <Pagination
            classNames={{
              active: "border-2 border-primary text-primary",
            }}
            position="center"
            size="sm"
            total={visitorLogsData.totalPages} //{1 visitorLogsData.visitorLogs.totalPages}
            siblings={1}
            page={activePage}
            onChange={setPage}
          />
        </div>
      </Card>
    </>
  );
};
