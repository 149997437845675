import { useDebouncedValue } from "@mantine/hooks";
import { useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import { House } from "src/common/api/types";
import colors from "src/common/colors";
import { useHouseQuery } from "src/common/hooks";
import { useExpandStore } from "src/common/store";
import { formatPhoneNumber } from "src/common/utils";
import {
  Accordion,
  Card,
  Loader,
  Tab as TabContainer,
  Table,
  TextInput,
  Typography,
} from "src/components";

// TODO replace this Pagination with updated web ui one
import { Pagination } from "@mantine/core";

export const HouseTab = () => {
  const { data: housesData } = useHouseQuery();
  const [, setExpanded] = useExpandStore();
  const [houseNumber, setHouseNumber] = useState("");
  const [mainContact, setMainContact] = useState("");
  const [debouncedHouseNumber] = useDebouncedValue(houseNumber, 500);
  const [debouncedMainContact] = useDebouncedValue(mainContact, 500);
  // pagination logic thanks to https://ilikekillnerds.com/2020/09/how-to-paginate-an-array-in-javascript/
  const [activePage, setPage] = useState(1);
  const perPage = 7;

  return (
    <Card className="h-full w-full" expandable>
      <div className="flex flex-wrap gap-x-3 gap-y-1">
        {/* //IDEA have this be select inputs already filled */}
        {/* I did not put the select input for houses because when empty it will reset to the previous state upon dropdown close */}
        <TextInput
          label="House"
          placeholder="Search by house"
          size="xs"
          onChange={(e) => {
            setHouseNumber(e.target.value);
            setPage(1);
          }}
          value={houseNumber}
        />
        <TextInput
          label="Main Contact"
          placeholder="Search by first name"
          size="xs"
          onChange={(e) => {
            setMainContact(e.target.value);
            setPage(1);
          }}
          value={mainContact}
        />
      </div>

      <div className="mt-5 h-[80%] overflow-y-auto">
        {!housesData ? (
          <div className="flex items-center justify-center h-full">
            <Loader size={"lg"} />
          </div>
        ) : (
          <>
            <Accordion
              onChange={() => setExpanded(true)}
              // UGLY maybe have the filter in its own variable or make a function that searches the houses data and return the new array
              items={housesData.houses
                ?.filter((house: House) => {
                  // i don't know if this is the best implementation of the search, if anything is better than this feel free to change it

                  if (!debouncedHouseNumber && !debouncedMainContact) {
                    return house;
                  }

                  if (debouncedHouseNumber) {
                    return house.number === debouncedHouseNumber;
                  }

                  if (debouncedMainContact) {
                    const mainContactFullName = `${house?.mainContact[0]?.fname} ${house?.mainContact[0]?.lname} ${house?.mainContact[0]?.lname2}`;

                    return mainContactFullName.includes(debouncedMainContact);
                  }

                  return house;
                })
                .slice(perPage * (activePage - 1), perPage * activePage)
                .map((house: House) => {
                  const { number, mainContact, residents, totalCars } = house;

                  return {
                    label: (
                      <div className="flex items-center justify-between">
                        <Typography label="House" fontState="medium">
                          {number}
                        </Typography>

                        <Typography label="Main Contact" fontState="medium">
                          {`${mainContact[0]?.fname} ${mainContact[0]?.lname} ${mainContact[0]?.lname2}`}
                        </Typography>

                        <Typography
                          label="Residents"
                          fontState="medium"
                          state="info"
                        >
                          {residents.length + mainContact.length}
                        </Typography>

                        <Typography label="Cars" fontState="medium">
                          {totalCars}
                        </Typography>
                      </div>
                    ),
                    children: (
                      <div className="p-3">
                        {/* tabs | residents */}
                        <TabContainer
                          variant="default"
                          rootStyle="mt-3"
                          tabs={[
                            {
                              title: "Residents",
                              content: residents && residents.length > 0 && (
                                <HouseResidentsTab
                                  residents={[...mainContact, ...residents]}
                                />
                              ),
                            },
                          ]}
                        />
                      </div>
                    ),
                  };
                })}
            />
          </>
        )}
      </div>

      {/* //TODO fix overflow issue on smaller computers. NTH */}
      <Pagination
        classNames={{
          active: "border-2 border-primary text-primary",
        }}
        position="center"
        size="sm"
        page={activePage}
        onChange={setPage}
        // UGLY maybe have the filter in its own variable or make a function that searches the houses data and return the new array
        total={parseInt(
          (
            housesData.houses?.filter((house: House) => {
              // i don't know if this is the best implementation of the search, if anything is better than this feel free to change it

              if (!debouncedHouseNumber && !debouncedMainContact) {
                return house;
              }

              if (debouncedHouseNumber) {
                return house.number === debouncedHouseNumber;
              }

              if (debouncedMainContact) {
                const mainContactFullName = `${house?.mainContact[0]?.fname} ${house?.mainContact[0]?.lname} ${house?.mainContact[0]?.lname2}`;

                return mainContactFullName.includes(debouncedMainContact);
              }

              return house;
            }).length / perPage
          ).toFixed(),
        )}
      />
    </Card>
  );
};

const HouseResidentsTab = ({ residents }: { residents: any }) => {
  return (
    <Table
      type="card"
      fontSize={"sm"}
      columns={[
        // "ID",
        "Contact",
        "Resident",
        "Phone",
        "Cars",
        "Authorization",
        "Status",
        // "Access",
        // "Action",
      ]}
      rowsData={residents.map((resident: any) => {
        const {
          // id,
          isMainContact,
          fname,
          lname,
          lname2,
          phone,
          cars,
          active,
          // access,
        } = resident;
        return {
          mainContact: isMainContact && (
            <FaCheckCircle color={colors.primary} size={"1rem"} />
          ),
          residentName: `${fname ? fname : ""} ${lname ? lname : ""} ${
            lname2 ? lname2 : ""
          }`,
          // id,
          phone: formatPhoneNumber(phone),
          cars: cars?.length,
          // access: access
          //   ? "All"
          //   : "Limited",
          authorization: (
            <Typography
              state={isMainContact ? "info" : undefined}
              fontState="medium"
            >
              {isMainContact ? "1st" : "2nd"}
            </Typography>
          ),
          status: (
            <Typography state={active ? "info" : "error"} fontState="medium">
              {active ? "Active" : "Inactive"}
            </Typography>
          ),
          //  ask what this does
          // action: (
          //   <FaImage
          //     color="#0D9E90"
          //     size={"1.5rem"}
          //   />
          // ),
        };
      })}
    />
  );
};
