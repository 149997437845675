import React from "react";
import { Navigate, useLocation, useRoutes } from "react-router-dom";
import { useAuth } from "src/common/auth";
import { AppLayout } from "../App";
import { Guard, Login, Logs } from "./index";

export default function RouterElement() {
  const element = useRoutes(
    // FIX check for proper default route
    [
      {
        index: true,
        path: "/",
        element: <Login />,
      },
      // Protected routes
      {
        element: <AppLayout />,
        children: [
          {
            path: "/post",
            element: (
              <RequireAuth>
                <Guard />
              </RequireAuth>
            ),
          },
          {
            path: "/logs",
            element: (
              <RequireAuth>
                <Logs />
              </RequireAuth>
            ),
          },
        ],
      },
    ],
  );

  return element;
}

/**
 * Redirects to the login page, but saves the current location a user
 * tried to go to when they were redirected. This allows to send them
 * along to that page after they login, which is a nicer user experience
 * than dropping them off on the home page. */
function RequireAuth({ children }: { children: JSX.Element }) {
  // TODO from react-query
  let auth = useAuth();
  // let auth = true;
  // let auth = queryClient.getQueryData("userAuth");

  let location = useLocation();

  if (!auth) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
