import { ColorInput as ColorInputMantine } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { ColorInputProps } from "./definitions";

export const ColorInput = ({
  label,
  error,
  disabled,
  required,
  size,
  withPicker = false,
  disallowInput = true,
  showColorSwatches = true,
  value,
  onChange,
}: ColorInputProps) => {
  return (
    <ColorInputMantine
      classNames={{
        input: `rounded-lg p-1 px-2 pl-7 shadow border-1 border-[#666666] focus:border-2 focus:border-primary ${classNames(
          { "hover:cursor-not-allowed": disabled },
        )}`,
      }}
      label={label}
      error={error}
      required={required}
      size={size}
      withPicker={withPicker}
      disallowInput={disallowInput}
      swatches={
        showColorSwatches
          ? [
              "#E9EBE2",
              "#323334",
              "#8E8E8E",
              "#AAA9AD",
              "#B30E16",
              "#1B3D81",
              "#AC4313",
              "#296E01",
              "#BDB8B0",
              "#DA680F",
              "#D4AF37",
              "#FDCC0D",
              "#5B0A91",
            ]
          : []
      }
      value={value}
      onChange={onChange}
    />
  );
};
