import create from "zustand";

type CapturedImages = {
  images: { plate: string; face: string };
  setImages: (image: string, type: "plate" | "face") => void;
};

const useCapturedImage = create<CapturedImages>()((set) => ({
  images: { face: "", plate: "" },
  setImages(image, type) {
    set((state) => {
      return {
        ...state,
        images: {
          ...state.images,
          [type]: image,
        },
      };
    });
  },
}));

export const useCapturedImagesStore = () => {
  const images = useCapturedImage((state) => state.images);
  const setImages = useCapturedImage((state) => state.setImages);

  return [images, setImages] as const;
};
