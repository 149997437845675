import { NotificationsProvider } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "react-query";
import { createWebStoragePersistor } from "react-query/createWebStoragePersistor-experimental";
import { ReactQueryDevtools } from "react-query/devtools";
import { persistQueryClient } from "react-query/persistQueryClient-experimental";
import { BrowserRouter, Outlet } from "react-router-dom";
import { logout } from "./common/auth";
import {
  BaseContainer,
  Button,
  Header,
  MainContainer,
  Navbar,
  NavBarLink,
  Tooltip,
} from "./components";
import { Camera, List } from "./components/assets/icons";
import { SvgLogo as Logo } from "./components/assets";
import ScreenSize from "./components/ScreenSize";
import RouterElement from "./pages/routes";

// TODO change assets on public/
export function AppLayout() {
  return (
    <>
      <Header
        logo={<Logo height={"35%"} width={"35%"} />}
        emergency={<Button text="Emergency Access" color="error" size="sm" />}
        showLogout
        logoutFunc={() => logout()}
      />

      <MainContainer className="relative">
        <Navbar
          className="!left-12 !top-6"
          alignment="vertical"
          position="top-left"
        >
          <Tooltip label="Guard Post" position="right">
            <NavBarLink
              to="/post"
              routerMethod={null}
              icon={<Camera height={"2rem"} width={"2rem"} />}
            />
          </Tooltip>
          {/* <NavBarLink
      active={false}
      routerMethod={null}
      icon={<Map height={"2rem"} width={"2rem"} />}
    /> */}
          <Tooltip label="Logs" position="right">
            <NavBarLink
              to="/logs"
              routerMethod={null}
              icon={<List height={"2rem"} width={"2rem"} />}
            />
          </Tooltip>
          {/* <NavBarLink
      active={false}
      routerMethod={null}
      icon={<Edit height={"2rem"} width={"2rem"} />}
    />
    <NavBarLink
      active={false}
      routerMethod={null}
      icon={<ShieldBadge height={"2rem"} width={"2rem"} />}
    />
    <NavBarLink
      active={false}
      routerMethod={null}
      icon={<CalendarOutline height={"2rem"} width={"2rem"} />}
    /> */}
        </Navbar>

        <Outlet />
      </MainContainer>
    </>
  );
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // REVIEW cache max time, this is 24h
    },
  },
});

// FIX have userId on some storage other than session or local
const sessionStoragePersistor = createWebStoragePersistor({
  storage: window.sessionStorage,
});

// const localStoragePersistor = createWebStoragePersistor({
//   storage: window.localStorage,
// });

persistQueryClient({
  queryClient,
  persistor: sessionStoragePersistor,
  // FIX implement buster string with proper nanoid across refreshes
  // buster: "buster-" + nanoid(),
});

//   queryClient,
//   persistor: localStoragePersistor,
// });

function App() {
  console.log("App() ", process.env.REACT_APP_EXAMPLE);
  return (
    <NotificationsProvider position="bottom-center" autoClose={5000}>
      <QueryClientProvider client={queryClient}>
        {process.env.NODE_ENV === "development" && <ReactQueryDevtools />}
        <BrowserRouter>
          <BaseContainer>
            {process.env.NODE_ENV === "development" && <ScreenSize />}
            <RouterElement />
          </BaseContainer>
        </BrowserRouter>
      </QueryClientProvider>
    </NotificationsProvider>
  );
}

export default App;
