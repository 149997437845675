import React from "react";
import { AlertingTextProps } from "./definitions";

export const AlertingText = ({
  children,
  type = "error",
  className,
}: AlertingTextProps) => {
  return (
    <div
      className={`animate-pulse border-2 border-${type} rounded-lg px-2 py-1 relative w-fit`}
    >
      {/* dot */}
      <span className="flex h-3 w-3 absolute -right-1 -top-1">
        <span
          className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-${type} opacity-75`}
        ></span>
        <span
          className={`relative inline-flex rounded-full h-3 w-3 bg-${type} `}
        ></span>
      </span>

      {/* text */}
      <span className={`text-${type} font-semibold ${className}`}>
        {children}
      </span>
    </div>
  );
};
