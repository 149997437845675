import axios from "axios";
import { headersAuth } from "src/common/auth";
import { LoginPost, VisitorsPost } from "src/common/schemas";
import { LogRes, UserRes, VisitorLogRes } from "./types";

// export const baseURL = `${process.env.REACT_APP_API_URL}/v1`;
export const baseURL = "https://api.sesam.enterprises/v1";

const axiosClient = axios.create({
  // FIX pipelines not working correctly cause `main` branch wack
  //  VERCEL LITTLE TROLL, HENCE HARD-CODING ENVxx
  baseURL,
  headers: { "Content-Type": "application/json" }, //, "Cache-Control": "no-cache" },
});

export const postLogin = async (body: LoginPost): Promise<any> => {
  const { data } = await axiosClient.post<any>("/login", JSON.stringify(body));
  return data;
};

export const postVisitor = async (body: VisitorsPost): Promise<any> => {
  const { data } = await axiosClient.post<any>(
    "/guard/visitors",
    JSON.stringify(body),
    {
      headers: headersAuth(),
    },
  );
  return data;
};

export const getCarOptions = async (params?: any): Promise<any> => {
  const { data } = await axiosClient.get("/car-options", {
    params,
    headers: headersAuth(),
  });
  return data;
};

export const getAccessLogs = async ({
  clusterId,
  page,
  perPage,
  userId,
  knowns,
  unknowns,
}: {
  clusterId: string;
  page?: number;
  perPage?: number;
  userId?: string;
  knowns?: boolean;
  unknowns?: boolean;
}): Promise<LogRes> => {
  const { data } = await axiosClient.get<LogRes>("/access-logs", {
    params: {
      cid: clusterId,
      page,
      perPage,
      uid: userId,
      knowns,
      unknowns,
    },
    headers: headersAuth(),
  });
  return data;
};

export const getVisitorLogs = async ({
  clusterId,
  page,
  perPage,
}: {
  clusterId: string;
  page?: number;
  perPage?: number;
}): Promise<VisitorLogRes> => {
  const { data } = await axiosClient.get<VisitorLogRes>("/guard/visitors", {
    params: {
      cid: clusterId,
      page,
      perPage,
    },
    headers: headersAuth(),
  });
  return data;
};

export const getUser = async ({
  userId,
  token,
}: {
  userId: string;
  token?: string;
}): Promise<any> => {
  const { data } = await axiosClient.get<any>(`/users/${userId}`, {
    headers: headersAuth(token),
  });

  return data;
};

export const getUsers = async ({
  clusterId,
  userId,
}: {
  clusterId: string;
  userId?: string | null;
  token?: string | null | undefined;
}): Promise<UserRes> => {
  const { data } = await axiosClient.get<UserRes>("/users", {
    params: { cid: clusterId, uid: userId },
    headers: headersAuth(),
  });
  return data;
};

export const getHouses = async ({
  clusterId,
  token,
}: {
  clusterId: string;
  token?: string;
}): Promise<any> => {
  const { data } = await axiosClient.get<any>(`/houses`, {
    headers: headersAuth(token),
    params: { cid: clusterId },
  });
  return data;
};
