import React from "react";
import { Chips, Chip } from "@mantine/core";
import { ChipsInputGroupProps } from "./definitions";
import { nanoid } from "nanoid";

export const ChipsInputGroup = ({
  direction,
  color = "teal",
  multiple,
  radius,
  size,
  spacing,
  variant,
  value,
  onChange,
  chips,
  chipsOnClick,
}: ChipsInputGroupProps) => {
  return (
    <Chips
      direction={direction}
      color={color}
      multiple={multiple}
      radius={radius}
      size={size}
      spacing={spacing}
      variant={variant}
      value={value}
      onChange={onChange}
    >
      {chips.map((chip) => (
        <Chip
          key={nanoid()}
          id={chip.id}
          value={chip.value}
          checked={chip.checked}
          defaultChecked={chip.defaultChecked}
          radius={chip.radius}
          variant={chip.variant}
          type={chip.type}
          size={chip.size}
          disabled={chip.disabled}
          onChange={chip.onChange}
          onClick={chip.onClick ? chip.onClick : chipsOnClick}
        >
          {chip.children}
        </Chip>
      ))}
    </Chips>
  );
};
