import classNames from "classnames";
import React from "react";
import { Tooltip } from "src/components/Tooltip";
import { useExpandStore } from "../common/store";
import { ExpandCard } from "./assets/icons";
import { ContentCardProps } from "./definitions";

export const Card = ({ children, expandable, className }: ContentCardProps) => {
  const [expanded, setExpanded] = useExpandStore();

  // FIX <Tooltip/> doesn't work with "absolute" positions
  // perhaps the tooltip needs to inherit the position of the child element
  return (
    <div className={`bg-white p-5 shadow-xl rounded-lg relative ${className}`}>
      {expandable && (
        // <Tooltip label="Expand Card">
        <button
          className="bg-white rounded-lg shadow-xl aspect-square grid place-items-center px-2 py-4 absolute left-[-1.5em] top-[50%] hover:bg-gray-200"
          onClick={() => setExpanded(!expanded)}
        >
          <ExpandCard
            className={`${classNames({
              "rotate-180": expanded,
            })}`}
            fill="black"
          />
        </button>
        // </Tooltip>
      )}

      {children}
    </div>
  );
};
